import React, { useEffect, useState, useCallback, useMemo, useRef } from 'react';
import { Group, Line, Rect } from 'react-konva';

import { useRefs } from 'contexts/RefContext';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { RectConfig } from 'konva/lib/shapes/Rect';
import { doesPolygonOverLap, generatePolygon, isCompletelyInsideFacet } from '../PanelUtil';
import { useKonvaScale } from 'hooks/useKonvaScale';
import { setAddMultiplePanel, setNewlyAddedPanel } from 'store/slices/PanelSlice';
import { KONVA_FACET_GROUP } from '../../../constants';
import { ORIENTATION } from 'store/slices/AddPanelModalSlice/types';
import Konva from 'konva';

const CustomRect = () => {
	const { konvaRef: stageRef, movablePanelRef } = useRefs();

	const scale = useKonvaScale();

	const { editModeEnabled, moveStage } = useSelector((state: RootState) => state.toolNewPostions.data);
	const { allRoofSegs } = useSelector((state: RootState) => state.roofData.data);

	const [startPoint, setStartPoint] = useState<null | { x: number; y: number; width: number; height: number }>(null);
	const [rectDims, setRectDims] = useState<null | { width: number, height:number }>(null);

	const targetFacetRef = useRef<RasterRoofSegment | null>(null);

	const { orientation: panelOrientation } = useSelector((state: RootState) => state.addPanelModal.data);
	const { enableMultipleAddPanel, deleteIndividualPanelMode, enableAddPanel } = useSelector((state: RootState) => state.panelSlice.data);
	const [commonAngle, setCommonAngle] = useState<any>(null);

	const dispatch = useDispatch();
	const handlePointerDown = () => {
		const stage = stageRef?.current;
		if(!enableAddPanel || !stage || moveStage ) return;
		
		const prevousTargetFacet = stage.findOne(`#${targetFacetRef.current?.id}`) as Konva.Line;
		prevousTargetFacet?.fill('transparent');
		const position = stage.getRelativePointerPosition();
		const pointerPosition = stage.getPointerPosition();
		if (!position || !pointerPosition) return;
		const targetFacetPolygon = stage.getAllIntersections(pointerPosition).find((node)=>node.hasName('movable-facet'));
		if (targetFacetPolygon) {
			dispatch(setAddMultiplePanel({shouldEnable:true}));
			targetFacetPolygon?.fill('rgba(255, 255, 0, 0.3)');
			
			const matchingRoofSeg = allRoofSegs.find((seg:any) => seg.id === targetFacetPolygon.id());
			if(matchingRoofSeg) {
				const width =  matchingRoofSeg.panelWidth  *  scale.x;
				const height =  matchingRoofSeg.panelHeight  * Math.cos(matchingRoofSeg.pitchDegrees * (Math.PI / 180)) *  scale.y;

				const rectWidth = width;
				const rectHeight = height;

				const angleRadians = (commonAngle * Math.PI) / 180;
				
				const centerX =
				pointerPosition.x - (rectWidth / 2) * Math.cos(angleRadians) +
				(rectHeight / 2) * Math.sin(angleRadians);
			
				const centerY =
				pointerPosition.y - (rectWidth / 2) * Math.sin(angleRadians) -
				(rectHeight / 2) * Math.cos(angleRadians);

				setStartPoint({x: position.x, y: position.y, width: width, height: height });
				// setRectDims({width, height});
				
				targetFacetRef.current = matchingRoofSeg;
				let rotationAngle = matchingRoofSeg.azimuthDegrees;
				const chnageOrientation = (matchingRoofSeg.orientation === 'POTRAITT' && panelOrientation === ORIENTATION.LANDSCAPE) ||
				(matchingRoofSeg.orientation === 'LANDSCAPE' && panelOrientation === ORIENTATION.PORTRAIT);

				if (chnageOrientation) {
					rotationAngle+=90;
				}
				rotationAngle %= 360;
				setCommonAngle(rotationAngle);
			}
		} else {
			const position = stage.getPointerPosition();
			const stageScale = stage.scale() as Konva.Vector2d;
			if(!position) return;
			
			const adjustedPointerPosition = {
				x: (position.x - stage.x()) / stageScale.x,
				y: (position.y - stage.y()) / stageScale.y,
			};
			movablePanelRef?.current?.visible(true);
			movablePanelRef?.current?.position(adjustedPointerPosition);
			movablePanelRef?.current?.startDrag();
		}
	};

	const handlePointerMove = () => {
		if (!stageRef?.current) return;

		const stage = stageRef.current;
		const position = stage.getRelativePointerPosition();

		if (!position || !startPoint || !startPoint.x || !startPoint.y || !targetFacetRef.current ) return;

		// dy<0: south, dy>0: north
		//dx<0: west, dx>0: east

		const dx = position.x - startPoint.x;
		const dy = position.y - startPoint.y;

		const angleDegrees = commonAngle;
		const angleRadians = (angleDegrees * Math.PI) / 180;
		
		const rotatedDx = dx * Math.cos(angleRadians) + dy * Math.sin(angleRadians);
		const rotatedDy = -dx * Math.sin(angleRadians) + dy * Math.cos(angleRadians);
		
		const baseWidth = startPoint.width || 0;
		const baseHeight = startPoint.height || 0;
		let projectedWidth = startPoint.width;
		let projectedHeight = startPoint.height;
		// if(rotatedDx < 0) {
		// 	projectedWidth = -baseWidth + dx * Math.cos(angleRadians) + dy * Math.sin(angleRadians);
		// } else {
		// 	projectedWidth = baseWidth + dx * Math.cos(angleRadians) + dy * Math.sin(angleRadians);
		// }
		projectedWidth = dx * Math.cos(angleRadians) + dy * Math.sin(angleRadians);

		
		// if(rotatedDy < 0) {
		// 	projectedHeight = -baseHeight -dx * Math.sin(angleRadians) + dy * Math.cos(angleRadians);
		// } else {
		// 	projectedHeight = baseHeight -dx * Math.sin(angleRadians) + dy * Math.cos(angleRadians);
		// }
		projectedHeight = -dx * Math.sin(angleRadians) + dy * Math.cos(angleRadians);

		
		setRectDims({
			width: projectedWidth,
			height: projectedHeight
		});
	};

	const smallRects = useMemo(() => {
		const smallRectsAsLines:any = [];
		const targetFacet = targetFacetRef.current;
		if(!targetFacet || !startPoint || !stageRef?.current || !rectDims) {
			return smallRectsAsLines;
		}
		const smallWidth = targetFacet.panelWidth  * scale.x;
		const smallHeight = targetFacet.panelHeight  * Math.cos(targetFacet.pitchDegrees * (Math.PI / 180)) *  scale.y;

		const angleInRadians = (commonAngle * Math.PI) / 180;
		const prebuildLineRects = stageRef.current.find(`.panel-${targetFacet.id}`);

		if (!rectDims.width || !rectDims.height) return [];

		const cols = Math.floor(Math.abs(rectDims?.width) / smallWidth);
		const rows = Math.floor(Math.abs(rectDims?.height) / smallHeight);
		
		const rowVector = Math.abs(rectDims.width) / rectDims.width;
		const coVector = Math.abs(rectDims.height) / rectDims.height;
		
		const rowConstant = rowVector > 0 ? 0 : 1;
		const colConstant = coVector > 0 ? 0 : 1;

		for (let row = 0; row < rows; row++) {
			for (let col = 0; col < cols; col++) {
				const localX = (col + rowConstant) * smallWidth * rowVector;
				const localY = (row + colConstant) * smallHeight * coVector;
				
				const points = [
					{ x: localX, y: localY },
					{ x: localX + smallWidth, y: localY },
					{ x: localX + smallWidth, y: localY + smallHeight },
					{ x: localX, y: localY + smallHeight },
				];
				
				const transformedPoints = points.map((point) => {
					const rotatedX = point.x * Math.cos(angleInRadians) - point.y * Math.sin(angleInRadians);
					const rotatedY = point.x * Math.sin(angleInRadians) + point.y * Math.cos(angleInRadians);
					
					const stageX = startPoint.x + rotatedX;
					const stageY = startPoint.y + rotatedY;
					
					return { x: stageX, y: stageY };
				});
				
				const flatPoints = [
					transformedPoints[0].x,
					transformedPoints[0].y,
					transformedPoints[1].x,
					transformedPoints[1].y,
					transformedPoints[2].x,
					transformedPoints[2].y,
					transformedPoints[3].x,
					transformedPoints[3].y,
					transformedPoints[0].x,
					transformedPoints[0].y,
				];
				
				const smallRectPolygon = generatePolygon(flatPoints);
				
				const hasCollision = prebuildLineRects.some(eachPanel => {
					const convertedPolygon = generatePolygon(eachPanel.attrs.points);
					return doesPolygonOverLap(smallRectPolygon, convertedPolygon);
				});

				const facetPolygon = stageRef.current?.findOne(`#${targetFacetRef.current?.id}`) as Konva.Line;
				const isInsideFacet = isCompletelyInsideFacet(flatPoints, (facetPolygon.points()));

				if(isInsideFacet && !hasCollision) {
					smallRectsAsLines.push(flatPoints);
				}
			}
		}
		
		return smallRectsAsLines;
	}, [startPoint, stageRef, scale, commonAngle, rectDims]);

	const handlePointerUp = () => {
		const newlyAddedPanels = smallRects.map((p: number[]) => {
			const exteriorCoords = [];
			for (let i = 0; i < p.length - 1; i += 2) {
				exteriorCoords.push([p[i] / scale.x, p[i + 1] / scale.y]);
			}
			const roofSeg = allRoofSegs.find((roofSeg) => roofSeg.id === targetFacetRef.current?.id);
			return {
				exteriorCoords,
				orientation: panelOrientation,
				roofSegmentId: targetFacetRef.current?.id,
				isGroundMount: !!roofSeg?.isGroundMount,
			};
		});
		setStartPoint(null);
		setRectDims(null);
		setCommonAngle(null);

		if(enableMultipleAddPanel && newlyAddedPanels.length > 0) {
			dispatch(setNewlyAddedPanel({addedPanels: newlyAddedPanels}));
		}
		
		if (movablePanelRef?.current) {
			movablePanelRef.current.stopDrag();
		}
		dispatch(setAddMultiplePanel({shouldEnable:false}));
	};

	useEffect(() => {
		if (!stageRef?.current || moveStage ) return;

		const stage = stageRef.current;

		stage.on('pointerdown', handlePointerDown);
		stage.on('pointermove', handlePointerMove);
		stage.on('pointerup', handlePointerUp);

		return () => {
			stage.off('pointerdown', handlePointerDown);
			stage.off('pointermove', handlePointerMove);
			stage.off('pointerup', handlePointerUp);
		};
	}, [stageRef, handlePointerDown, handlePointerMove, handlePointerUp, enableAddPanel, editModeEnabled, panelOrientation, moveStage]);

	return (
		<>
			<Group
				x={startPoint?.x || 0}
				y={startPoint?.y || 0}
				rotation={commonAngle}
				id={`${targetFacetRef.current?.id}${KONVA_FACET_GROUP.IN_PROGRESS_GROUP_NAME}${KONVA_FACET_GROUP.GROUP_NAME_SEPARATOR}panels`}
				name={`${KONVA_FACET_GROUP.IN_PROGRESS_GROUP_NAME}${KONVA_FACET_GROUP.GROUP_NAME_SEPARATOR}panels`}
			>
				{/* Main Rectangle */}
				<Rect
					width={rectDims?.width}
					height={rectDims?.height}
					stroke="black"
					strokeWidth={1}
				/>
			</Group>
			{smallRects.map((points:any, index:any) => (
				<Line
					key={index}
					points={points}
					stroke={'rgba(0, 128, 255, 0.5)'}
					strokeWidth={1.4}
					closed={true}
				/>
			))}
		</>
	);
};

export default CustomRect;